var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ais-instant-search",
    {
      class: _vm.classes.scope,
      attrs: { "search-client": _vm.searchClient, "index-name": _vm.indexName },
    },
    [
      _c("ais-configure", {
        attrs: { hitsPerPage: _vm.hitsPerPage, facetFilters: _vm.facetFilters },
      }),
      _vm._v(" "),
      _c("ais-autocomplete", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var indices = ref.indices
              var refine = ref.refine
              return _c(
                "div",
                {
                  class: _vm.classes.autocomplete + " " + _vm.openClass,
                  attrs: { onclick: "" },
                },
                [
                  _c("vue-autosuggest", {
                    ref: "suggestComponent",
                    attrs: {
                      suggestions: _vm.indicesToSuggestions(indices),
                      "get-suggestion-value": _vm.getSuggestionValue,
                      "should-render-suggestions": _vm.shouldRenderSuggestions,
                      "input-props": _vm.inputProps,
                    },
                    on: {
                      "hook:mounted": _vm.handleChildMounted,
                      selected: _vm.onSelect,
                      input: function ($event) {
                        return _vm.onInput(refine)
                      },
                      focus: function ($event) {
                        return _vm.$emit("focus")
                      },
                      blur: function ($event) {
                        return _vm.$emit("blur")
                      },
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.onEnter($event)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "before-input",
                          fn: function () {
                            return [
                              _vm._t("before-input", null, null, _vm.$props),
                            ]
                          },
                          proxy: true,
                        },
                        _vm.queryString.length === 0
                          ? {
                              key: "after-input",
                              fn: function () {
                                return [
                                  _vm._t("after-input", null, null, _vm.$props),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        {
                          key: "after-suggestions",
                          fn: function () {
                            return [
                              _vm.numberOfHits === 0
                                ? _vm._t("no-results", null, null, {
                                    queryString: _vm.queryString,
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.numberOfHits > _vm.hitsPerPage
                                ? _c(
                                    "div",
                                    {
                                      on: {
                                        mouseup: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _vm._t("more-results", null, null, {
                                        numberOfHits: _vm.numberOfHits,
                                        queryString: _vm.queryString,
                                        facetFilters: _vm.facetFilters,
                                      }),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "default",
                          fn: function (ref) {
                            var suggestion = ref.suggestion
                            return [
                              _vm._t(
                                "default",
                                [
                                  suggestion.item.title
                                    ? _c("ais-highlight", {
                                        attrs: {
                                          hit: suggestion.item,
                                          "highlighted-tag-name": "span",
                                          attribute: "title",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                null,
                                { suggestion: suggestion }
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.queryString,
                      callback: function ($$v) {
                        _vm.queryString = $$v
                      },
                      expression: "queryString",
                    },
                  }),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }