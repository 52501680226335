import Vue from 'vue'
import SearchBox from 'Patterns/vue/components/SearchBox.vue'
import InstantSearch from 'vue-instantsearch'
import renderVueApp from '../../../../assets/js/helpers/vueBoot'

Vue.use(InstantSearch)

const searchBoxSelector = '#vue-search-box'
const rootElement = document.querySelector(searchBoxSelector)
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

export default class SearchBoxComponent {
  /**
   * Creates an instance of SearchBoxComponent.
   */
  constructor () {
    this.$trigger = rootElement
    if (this.$trigger && !isIE11) {
      this.app = renderVueApp(Vue, rootElement, SearchBox)
    }
  }
}

new SearchBoxComponent()
