<template>
  <ais-instant-search
    :search-client="searchClient"
    :index-name="indexName"
    :class="classes.scope"
  >
    <ais-configure
      :hits-per-page.camel="hitsPerPage"
      :facet-filters.camel="facetFilters"
    />
    <ais-autocomplete>
      <div
        slot-scope="{ indices, refine }"
        :class="classes.autocomplete + ' ' + openClass"
        onclick=""
      >
        <!--
         empty onclick added to fix issue with older browsers (iOS Safari) not propagating click events properly
         https://github.com/darrenjennings/vue-autosuggest/issues/67#issuecomment-506506587
        -->
        <vue-autosuggest
          @hook:mounted="handleChildMounted"
          ref="suggestComponent"
          v-model="queryString"
          :suggestions="indicesToSuggestions(indices)"
          :get-suggestion-value="getSuggestionValue"
          :should-render-suggestions="shouldRenderSuggestions"
          :input-props="inputProps"
          @selected="onSelect"
          @input="onInput(refine)"
          @keyup.enter.native="onEnter"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        >
          <template #before-input>
            <slot name="before-input" v-bind="$props"></slot>
          </template>
          <template #after-input v-if="queryString.length === 0">
            <slot name="after-input" v-bind="$props"></slot>
          </template>
          <template #after-suggestions>
            <slot name="no-results" v-if="numberOfHits === 0" v-bind="{ queryString }"></slot>
            <div @mouseup.stop v-if="numberOfHits > hitsPerPage">
              <slot name="more-results" v-bind="{ numberOfHits, queryString, facetFilters }"></slot>
            </div>
          </template>
          <template slot-scope="{ suggestion }">
            <slot name="default" v-bind="{ suggestion }">
              <ais-highlight
                v-if="suggestion.item.title"
                :hit="suggestion.item"
                highlighted-tag-name="span"
                attribute="title"
              />
            </slot>
          </template>
        </vue-autosuggest>
      </div>
    </ais-autocomplete>
  </ais-instant-search>
</template>

<script>
import algoliasearch from 'algoliasearch/lite'
import { VueAutosuggest } from 'vue-autosuggest'
import Tracking from 'Shared/components/tracking/tracking'

export default {
  name: 'SearchBox',
  components: { VueAutosuggest },
  props: {
    appKey: {
      type: String,
      required: true
    },
    apiKey: {
      type: String,
      required: true
    },
    facetFilters: {
      type: Array,
      default: () => []
    },
    pageTemplates: {
      type: Array,
      default: () => []
    },
    indexName: {
      type: String,
      required: true
    },
    minimumQueryLength: {
      type: Number,
      default: 1
    },
    hitsPerPage: {
      type: Number,
      default: 5
    },
    altText: String,
    placeholder: String,
    debounce: {
      type: Number,
      default: 300
    },
    classes: {
      type: Object,
      default: () => ({
        autocomplete: 'SearchBox__autocomplete',
        input: 'Form-input Form-input--lg',
        existingInputMask: 'SearchBox__inputbar--hidden',
        componentAttrPrefix: null
      })
    }
  },
  data () {
    const searchClient = algoliasearch(this.appKey, this.apiKey)

    if (searchClient) {
      const searchBase = searchClient.search

      searchClient.search = function (requests) {
        if (requests.every(({ params }) => !params.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
              hitsPerPage: 0,
              exhaustiveNbHits: false,
              query: '',
              params: ''
            }))
          })
        }

        return searchBase.call(this, requests)
      }
    }

    return {
      searchClient,
      queryString: '',
      numberOfHits: null,
      inputProps: {
        'aria-label': this.altText,
        autocomplete: 'off',
        class: this.classes.input,
        'data-tracking-property': 'search_text',
        name: 'q',
        placeholder: this.placeholder,
        type: 'search'
      },
      isOpen: false
    }
  },
  mounted () {
    this.hideExistingSearchBox()
    this.inputTO = null

    if (typeof (IntersectionObserver) !== 'undefined') {
      this.observer = new IntersectionObserver((entries, opts) => {
        entries.forEach(entry => {
          if (entry.target === this.$el && !entry.isIntersecting && this.isOpen) {
            const component = this.$refs.suggestComponent
            if (component) {
              // this will actually close the autosuggest and perform all class changes
              component.loading = true
            }
          }
        })
      }, {
        root: null, // default is the viewport
        threshold: 0 // element has left the viewport
      })

      this.observer.observe(this.$el)
    }
  },
  beforeDestroy () {
    if (!this.observer) return
    this.observer.unobserve(this.$el)
  },
  computed: {
    openClass: function () {
      return this.isOpen ? this.classes.autocomplete + '-open' : ''
    }
  },
  methods: {
    handleChildMounted () {
      this.$watch(
        vm => vm.$refs.suggestComponent.isOpen,
        val => {
          const component = this.$refs.suggestComponent
          this.$emit('toggle', val, val ? component.$refs[Object.getOwnPropertyNames(component.$refs)[0]][0].$el.parentElement : null)
          this.$data.isOpen = val
        }
      )
    },
    // on enter submit the form
    onEnter (e) {
      this.removeDomElement('#searchInput')
      e.target.form.submit()
    },
    // this method triggers upon option selection
    onSelect (selected) {
      // check for empty selection (ex. enter clicked) and ssr
      if (selected === null || typeof document === 'undefined') {
        return
      }

      const trackingData = JSON.stringify({
        name: 'auto suggest tap',
        step: 'search',
        position: 'content',
        search_text: this.queryString,
        link_text: selected.item.title,
        link: selected.item.url
      })

      Tracking.customRedirectWithTracking(selected.item.url, {
        eventName: 'button tap',
        eventType: ['click'],
        data: trackingData
      })
    },
    // triggers on input update
    onInput (refine) {
      if (this.queryString.length >= this.minimumQueryLength) {
        if (this.inputTO) {
          clearTimeout(this.inputTO)
        }
        this.inputTO = setTimeout(() => {
          this.inputTO = null
          return refine(this.queryString)
        }, this.$props.debounce)
      }
    },
    /**
     * This method defines the rule for when
     * we start to display an autocomplete
     * box with results
     */
    shouldRenderSuggestions (size, loading) {
      return this.queryString.length >= this.minimumQueryLength && size >= 0 && !loading && !this.inputTO
    },
    /*
     * triggers upon highlighting a value
     * and returns the string which will
     * appear in the input field
     */
    getSuggestionValue (suggestion) {
      return suggestion.item.title
    },
    /**
     * Formats the returned indices
     * from the performed search
     */
    indicesToSuggestions (indices) {
      return indices.map(({ hits, results }) => {
        this.numberOfHits = results.nbHits
        let mappedHits = hits.map((hit) => {
          Object.assign(hit, { 'type': this.pageTemplates.filter((el) => el.template === hit.pageTemplate).pop() })
          return hit
        })
        return {
          data: mappedHits
        }
      })
    },
    hideExistingSearchBox () {
      const existingSearchBox = document.getElementById('searchInput')

      if (existingSearchBox) {
        existingSearchBox.classList.add(this.classes.existingInputMask)
        setTimeout(() => {
          this.removeDomElement(existingSearchBox)
        }, 2000)
      }
    },
    removeDomElement (el) {
      if (!this.isElement(el)) {
        el = document.querySelector(el)
      }
      return el ? el.parentNode.removeChild(el) : false
    },
    isElement (element) {
      return element instanceof Element || element instanceof HTMLDocument
    }
  }
}
</script>

<style lang="scss">
@import "shared/site/patterns/vue/tokens";

.SearchBox__inputbar--hidden {
  position: absolute !important;
  width: calc(100% - 5.6rem) !important;
}

.SearchBox__autocomplete {
  display: flex;

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  & *,
  & mark {
    color: $black;
  }
}

.autosuggest__results { // sass-lint:disable-line class-name-format
  background: $white;
  border-radius: 0.4rem;
  box-shadow: $shadow-m;
  position: absolute;
  // todo: if time, fix this calc approach
  width: calc(100% + 5.6rem); // 56px is the search button
  z-index: 1;
}

.autosuggest__results-item { // sass-lint:disable-line class-name-format
  cursor: pointer;
  overflow: hidden;
  padding: 1.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.autosuggest__results-item--highlighted { // sass-lint:disable-line class-name-format
  background-color: $athens-gray;

  &:first-child {
    border-radius: 0.4rem 0.4rem 0 0;
  }

  &:last-child {
    border-radius: 0 0 0.4rem 0.4rem;
  }
}

.autosuggest__results-container { // sass-lint:disable-line class-name-format
  position: relative;
}

.ais-Highlight { // sass-lint:disable-line class-name-format
  font-weight: 600;
}

.ais-Highlight-highlighted { // sass-lint:disable-line class-name-format
  font-weight: 200;
}
</style>
