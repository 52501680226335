// exchange vue interpolation `{{ }}` with js one and execute it
function interpolateText (text, vars) {
  if (!text) {
    return
  }

  text = text.replace(new RegExp('{{', 'g'), '${').replace(new RegExp('}}', 'g'), '}')
  return new Function('vars', 'let {' + Object.getOwnPropertyNames(vars).toString() + '} = vars; return `' + text + '`;').call(null, vars) // eslint-disable-line
}

// builds vNodes of DOMNode
function buildVNodes (domElement, createElement, props) {
  if (domElement.content) {
    domElement = domElement.content
  }

  if (!domElement.hasChildNodes()) {
    const value = domElement.nodeValue
    return interpolateText(value, props)
  }
  let vNodes = []
  let children = domElement.childNodes

  children = Array.prototype.filter.call(children, (el) => el.nodeType !== Node.TEXT_NODE || el.nodeValue.replace(/\s/g, '').length)

  if (children.length === 1 && children[0].nodeType === Node.TEXT_NODE) {
    return interpolateText(children[0].nodeValue, props)
  }

  for (let i = 0; i < children.length; i++) {
    // try to resolve ':attrName="value.subValue"' using `props[value][subValue]`
    let localProps = Array.prototype.reduce.call((children[i].attributes || []), (carry, attr) => {
      if (attr.name.substr(0, 1) !== ':') {
        return carry
      }

      carry[attr.name.substr(1)] = attr.value.split('.').reduce((carry, prop) => {
        return carry[prop]
      }, props)
      return carry
    }, {})

    Object.assign(localProps, props)
    vNodes.push(
      createElement(
        (children[i].tagName || 'span').toLowerCase(),
        {
          props: localProps,
          attrs: Array.prototype.reduce.call((children[i].attributes || []), (carry, attr) => {
            if (['@', ':'].indexOf(attr.name.substr(0, 1)) !== -1) {
              return carry
            }
            carry[attr.name] = interpolateText(attr.value, props)
            return carry
          }, {})
        },
        buildVNodes(children[i], createElement, props)
      )
    )
  }

  return vNodes
}

function renderVueApp (Vue, domElement, component) {
  let slots = domElement.querySelectorAll('template')
  let parsedSlots = Array.prototype.reduce.call(slots, (carry, element) => {
    carry[element.attributes[0].name.substr(1)] = element
    return carry
  }, {})

  return new Vue({
    el: domElement,
    render: (createElement) => {
      let keys = Object.getOwnPropertyNames(parsedSlots)
      let builtNodes = {}
      keys.forEach((key) => {
        builtNodes[key] = (props) => buildVNodes(parsedSlots[key], createElement, props)
      })
      let events = {}
      let props = JSON.parse(domElement.dataset.props)
      for (let prop in props) {
        if (prop.startsWith('@')) {
          events[prop.substr(1)] = new Function(props[prop]) // eslint-disable-line
          delete props[prop]
        }
      }
      return createElement(component, {
        props,
        scopedSlots: builtNodes,
        on: events
      })
    }
  })
}

export default renderVueApp
